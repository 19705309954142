@use '../App.scss';

.main-container {
  margin-top: 140px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-container {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.main-container-no-navbar {
  margin-top: 12px;
}
