@import '../../App';

.techsphere-logo-container {
  display: inline-block;
}

.techsphere-logo {
  height: 52px;
  width: auto;
}
