@use '../../_App';

.footer-container {
  background-color: #ffffff;
  padding: 100px 0px;
  max-width: 1260px;
  margin: 0 auto;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.footer-content {
  margin-left: 0vw;
  margin-right: 0vw;
  width: 100%;
}

@media (min-width: 992px) {
  .extra-button-container {
    float: right;
  }
}

@media (max-width: 991px) {
  .extra-button-container {
    margin-top: 9px;
  }
}

.footer-content {
  a + a {
    margin-left: 40px;
  }
}

#copyright {
  //color: #6b7b8a !important;
  font-size: 12px;
  margin-top: 24px;
}

#copyright-link {
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
    background-color: transparent;
    text-decoration: underline;
  }
}

#extra-link {
  font-weight: 600;
  font-size: 0.9rem;
}

@media (min-width: 1024px) {
  #extra-link {
    float: right;
  }
}

#extra-container {
  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited,
  a:hover,
  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }
}

.footer-logo {
  margin-bottom: 24px;
  width: 60px;
  height: auto;
}
