@import '../../App';

.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}

/* PRIMARY */
.storybook-button--primary {
  color: white !important;
  background-color: $primary-500;
}

.storybook-button--primary:hover {
  background-color: $primary-600 !important;
}

.storybook-button--primary:active {
  background-color: $primary-500 !important;
}

.storybook-button--primary:disabled {
  background-color: $primary-400 !important;
}

/* ACCENT */
.storybook-button--accent {
  color: white;
  background-color: $accent-500 !important;
}

.storybook-button--accent:hover {
  background-color: $accent-600;
}

.storybook-button--accent:active {
  background-color: $accent-500 !important;
}

.storybook-button--accent:disabled {
  background-color: $accent-400 !important;
}

/* GREY */
.storybook-button--grey {
  color: white;
  background-color: $grey-500 !important;
}

.storybook-button--grey:hover {
  background-color: $grey-600 !important;
}

.storybook-button--grey:active {
  background-color: $grey-500 !important;
}

.storybook-button--grey:disabled {
  background-color: $grey-400 !important;
}

/* WARNING */
.storybook-button--warning {
  color: white;
  background-color: $warning-500 !important;
}

.storybook-button--warning:hover {
  background-color: $warning-600 !important;
}

.storybook-button--warning:active {
  background-color: $warning-500 !important;
}

.storybook-button--warning:disabled {
  background-color: $warning-400 !important;
}

/* ALERT */
.storybook-button--alert {
  color: white;
  background-color: $alert-500 !important;
}

.storybook-button--alert:hover {
  background-color: $alert-600 !important;
}

.storybook-button--alert:active {
  background-color: $alert-500 !important;
}

.storybook-button--alert:disabled {
  background-color: $alert-400 !important;
}

/* SUCCESS */
.storybook-button--success {
  color: white;
  background-color: $success-500 !important;
}

.storybook-button--success:hover {
  background-color: $success-600 !important;
}

.storybook-button--success:active {
  background-color: $success-500 !important;
}

.storybook-button--success:disabled {
  background-color: $success-400 !important;
}

/* INFO */
.storybook-button--info {
  color: white;
  background-color: $info-500 !important;
}

.storybook-button--info:hover {
  background-color: $info-600 !important;
}

.storybook-button--info:active {
  background-color: $info-500 !important;
}

.storybook-button--info:disabled {
  background-color: $info-400 !important;
}

/* SECONDARY */
.storybook-button--secondary {
  color: #333 !important;
  background-color: transparent;
  box-shadow: #adb5bd 0px 0px 0px 2px inset;
}

.storybook-button--secondary:hover {
  box-shadow: #21252a 0px 0px 0px 2px inset;
}

.storybook-button--secondary:active {
  box-shadow: #adb5bd 0px 0px 0px 2px inset;
}

.storybook-button--secondary:disabled {
  color: #cdd3d9 !important;
  box-shadow: #cdd3d9 0px 0px 0px 2px inset;
}

/* LINK */
.storybook-button--link {
  color: #009bff !important;
  background-color: transparent;
}

.storybook-button--link:hover {
  color: #0067aa !important;
}

.storybook-button--link:active {
  color: #009bff !important;
}

.storybook-button--link:disabled {
  color: #aadeff !important;
}

/* .storybook-button--icon-small {
    font-size: 12px;
    padding: 10px 32px 10px 16px;
  }

  .storybook-button--icon-medium {
    font-size: 14px;
    padding: 11px 36px 11px 20px;
  }

  .storybook-button--icon-large {
    font-size: 16px;
    padding: 12px 40px 12px 24px;
  } */

.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}

.storybook-button--medium {
  font-size: 14px;
  padding: 10px 26px;
}

.storybook-button--large {
  font-size: 20px;
  padding: 20px 32px;
}

.iconRight {
  position: static;
  line-height: 10px;
  display: inline;
  align-items: center;
  text-align: center;
  margin: 0 0 0 10px;
}

.iconLeft {
  position: static;
  line-height: 10px;
  display: inline;
  align-items: center;
  text-align: center;
  margin: 0 10px 0 0px;
}

.anticon {
  vertical-align: 0 !important;
}
