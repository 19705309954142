@import '../../_App';
@import '../../features/auth/components/Login/Login';

.techsphere-logo-container {
  margin-left: -10px;
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;

  .login-wrapper {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }

  #title {
    width: 100%;

    .login-form-subtitle {
      margin-top: 36px;
    }

    h2 {
      color: $primary-500;
      font-weight: 700;
    }

    h3 {
      color: $primary-500;
      font-weight: 700;
    }

    h4 {
      color: $primary-500;
      font-weight: 700;
    }
  }
}

.login-form-extra-link {
  color: $info-500;
  font-size: 14px;
  text-decoration: none;
}

.login-form-extra-link:hover {
  text-decoration: underline;
}
