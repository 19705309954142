@import url(bootstrap/dist/css/bootstrap.min.css);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700');

@font-face {
  font-family: 'Graphik-400-Regular';
  src: url(./assets/Graphik-400-Regular.otf);
}

@font-face {
  font-family: 'Graphik-700-Bold';
  src: url(./assets/Graphik-700-Bold.otf);
}

@font-face {
  font-family: 'Graphik-600-Semibold';
  src: url(./assets/Graphik-600-Semibold.otf);
}

$graphik: 'Graphik-400-Regular', sans-serif;
$graphik-bold: 'Graphik-700-Bold', sans-serif;
$graphik--600-semibold: 'Graphik-600-Semibold', sans-serif;

//primary
$primary-900: #150328;
$primary-800: #2a0650;
$primary-700: #3f0977;
$primary-600: #540c9f;
$primary-500: #7310db;
$primary-400: #9945f1;
$primary-300: #b273f5;
$primary-200: #cca2f8;
$primary-100: #e5d0fc;
$primary-50: #f3eafd;

//accent
$accent-900: #330015;
$accent-800: #66002a;
$accent-700: #990040;
$accent-600: #cc0055;
$accent-500: #ff006a;
$accent-400: #ff2b83;
$accent-300: #ff559c;
$accent-200: #ff80b4;
$accent-100: #ffaacd;
$accent-50: #ffd5e6;

//grey
$grey-900: #21252a;
$grey-800: #33393f;
$grey-700: #495057;
$grey-600: #858d95;
$grey-500: #adb5bd;
$grey-400: #cdd3d9;
$grey-300: #dde1e5;
$grey-200: #e8ebee;
$grey-100: #f1f3f5;
$grey-50: #f8f9fa;

//warning
$warning-900: #823218;
$warning-800: #a85325;
$warning-700: #bf7230;
$warning-600: #d4943b;
$warning-500: #e8b64a;
$warning-400: #f0ca60;
$warning-300: #f6db73;
$warning-200: #f9e594;
$warning-100: #fdf3c9;
$warning-50: #fffbec;

//alert
$alert-900: #590f18;
$alert-800: #7f191f;
$alert-700: #9d2226;
$alert-600: #bf2d2e;
$alert-500: #d03f40;
$alert-400: #de5a54;
$alert-300: #e7736f;
$alert-200: #f2a09e;
$alert-100: #f5c0be;
$alert-50: #fbe4e3;

//success
$success-900: #1e4c40;
$success-800: #2f6959;
$success-700: #397b65;
$success-600: #459275;
$success-500: #54a885;
$success-400: #64ba96;
$success-300: #82d3b0;
$success-200: #a4eac9;
$success-100: #d0f5e3;
$success-50: #f1fcf6;

//info
$info-900: #003455;
$info-800: #004d80;
$info-700: #0067aa;
$info-600: #0081d5;
$info-500: #009bff;
$info-400: #2bacff;
$info-300: #55bcff;
$info-200: #80cdff;
$info-100: #aadeff;
$info-50: #d5eeff;

$max-container-size: 1260px;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.App {
  height: 100%;
  padding: 5px;
}

.title {
  font-weight: 700;
  font-size: 36px;
}

.sub-title {
  font-weight: 700;
  font-size: 20px;
}

///TYPOGRAPHY

.body-small {
  /* body-small */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: $graphik;
}

.body {
  /* body */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: $graphik;
}

.body-large {
  /* body-large */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  font-family: $graphik;
}

.semi-bold {
  font-family: $graphik--600-semibold !important;
}

.bold {
  font-family: $graphik-bold !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: $graphik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  /* Heading 1 */
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  font-family: $graphik;
}

h2 {
  /* Heading 2 */
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  font-family: $graphik;
}

h3 {
  /* Heading 3 */
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  font-family: $graphik;
}

h4 {
  /* Heading 4 */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  font-family: $graphik;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-divider-horizontal {
  width: 100% !important;
}

.ant-divider-vertical {
  height: auto;
  background-color: $grey-400;
}

.noti {
  border-radius: 8px;

  .ant-notification-notice-content {
    width: 100%;
    height: 100%;
    display: inline-flex;
  }

  .ant-notification-notice-icon {
    vertical-align: middle;
    display: inline-block;
    padding-top: 4px;
  }

  .ant-notification-notice-message {
    height: 100%;
    width: 100%;
    color: white !important;
    // font-family: $graphik;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 62px;
    margin-top: 2px;
    display: flex;
    align-items: center;
  }

  .noti-logo {
    font-size: 40px;
    color: white;
  }
}

.noti-success {
  @extend .noti;
  background: $success-900;
}

.noti-error {
  @extend .noti;
  background: $alert-500;
}
