@import '../../../../App';

.login-form {
  width: 400px;
  box-sizing: inherit;
  text-align: left;
  background-color: inherit;
  margin-top: 25px !important;

  .login-button {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: $primary-500;
    margin-top: 20px;
  }

  .login-button:hover {
    color: white !important;
    background-color: $primary-600;
    border: 1px solid $primary-600 !important;
  }

  .oauth-button {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid $primary-500;
    margin-bottom: 15px;
    padding-top: 10px;
  }

  .oauth-icon {
    width: 25px;
    height: 25px;
    margin-top: -5px;
  }
}

.login-divider {
  margin-bottom: 0px !important;
}
