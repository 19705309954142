@import '../../App';

.page-outer-container {
  background-color: white;
  margin-top: 80px;

  .page-container {
    max-width: 500px;
    background-color: inherit;

    .logo-row {
      margin-bottom: 32px;
      margin-left: -10px;
    }

    .page-title {
      @extend .semi-bold;
      color: $primary-500 !important;
    }

    .page-subtitle {
      @extend .body;
    }

    .page-subtitle-row {
      margin-top: -5px;
      margin-bottom: 40px;
    }
  }
}
